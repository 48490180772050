@import '../../../semantic/globals/site.variables';

.usageReimbursementContainer {
  margin-bottom: 1rem;
  flex: 1;
  display: flex;
}

.usageReimbursement {
  border: none;
  background: none;
  padding: 0;
  margin-left: 0.5rem;
}

.usageReimbursementActive {
  color: @primaryColor;
  cursor: pointer;
}
