@import '../../../semantic/globals/site.variables';
@import 'styles/responsive';

.type-button {
  width: 100%;
  float: left;
  margin: 0;
  padding: 10px;

  @media @mobile {
    margin: 0 !important;
    margin-top: 1em !important;
  }

  .type-label {
    font-weight: @semiBold !important;
    float: left;
    line-height: 22px;
    svg {
      float: left;
      margin-right: 0.75rem;
    }
  }

  .selection-icon {
    float: right;
    margin-right: 0 !important;
  }
}
