.markdown-body table table {
  font-size: 14px;

  td {
    padding: 5px;
  }
}

.method {
  color: #fff;
  font-weight: bold;
  padding: 0.4rem 0.7rem;
  font-size: 0.9em;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  text-transform: uppercase;
  &.GET {
    background: #39a14f;
  }
  &.POST {
    background: #ea4335;
  }
  &.PUT {
    background: #fabc09;
  }
  &.DELETE {
    background: #4285f4;
  }
  &.PATCH {
    background: #f09300;
  }
  &.HEAD {
    background: #ab47bc;
  }
  &.OPTIONS {
    background: #2196f3;
  }
}

.markdown-body {
  h1 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.5em;
  }
  table {
    thead,
    th {
      padding: 0.8rem;
      background: #fafafa;
    }
    tr,
    th,
    td,
    tbody,
    thead {
      border: none;
    }
    td {
      border: none;
      padding: 1rem;
    }
  }
}

.nocturnal-theme {
  .markdown-body {
    code {
      background: #1a1a1a;
    }
  }
}
