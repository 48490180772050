@import '../../../../semantic/globals/site.variables';
@import 'styles/responsive';

.search-filters-modal,
.nocturnal-theme .search-filters-modal {
  @media @mobile {
    margin-bottom: 10px;
    > .ui.label {
      cursor: text;
    }
  }

  > .ui.label {
    cursor: pointer;
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;

    &:hover {
      background: var(--primary-color);
    }
  }
}

.borderless-blue-button {
  background: transparent;
  border: none;
  box-shadow: transparent;
  color: #008cd9;
  margin: 0 1rem;
  cursor: pointer;

  font-family:
    Inter,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Helvetica,
    'Segoe UI',
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji';
}
