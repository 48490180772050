@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(5rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideInFromLeft {
  animation: 0.25s ease-out 0s 1 slideInFromLeft;
}

.slideInFromRight {
  animation: 0.25s ease-out 0s 1 slideInFromRight;
}

.panelText {
  padding: 0.5rem 2rem 1rem 2rem;
}
