@import '../../semantic/globals/site.variables';

/* Reponsive.less */

@tinyBreakpoint: 380px;
@mobileBreakpoint: 768px;
@tabletBreakpoint: 1128px;

@tinyMax: (@tinyBreakpoint - 1);
@mobileMax: (@mobileBreakpoint - 1);
@tabletMax: (@tabletBreakpoint - 1);

@tiny: ~'only screen and (max-width: @{tinyMax})';
@mobile: ~'only screen and (max-width: @{mobileMax})';
@desktop: ~'only screen and (min-width: @{mobileBreakpoint})';
@tablet: ~'only screen and (min-width: @{mobileBreakpoint}) and (max-width: @{tabletMax})';

/* end Responsive.less */

@menuWidth: 250px;
@menuPadding: 25px;

// TODO: hook this up to vars somehow?

.sidebar-layout {
  &.with-env-banner {
    .sidebar-layout-menu {
      position: sticky;
      float: left;
      height: calc(100vh - @environmentBannerHeight);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: @menuWidth;
        height: @environmentBannerHeight;
        background: @mainMenuBackground;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        z-index: 1;
      }

      &--offscreen {
        position: fixed;
        height: 100vh;
      }

      .nocturnal-theme & {
        &:after {
          background: @nocturnalMainMenuBackground;
          border-right-color: rgba(255, 255, 255, 0.1);
        }
      }
    }

    .sidebar-layout-content .app-wrapper {
      min-height: calc(100vh - (30px + @environmentBannerHeight));
    }
  }

  &-menu {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    width: @menuWidth;
    height: 100vh;
    padding: @menuPadding 0 0 0;
    background: @mainMenuBackground;
    border-right: 1px solid rgba(0, 0, 0, 0.05);

    .nocturnal-theme & {
      background: @nocturnalMainMenuBackground;
      border-right-color: rgba(255, 255, 255, 0.1);
    }

    &-wrap {
      padding-left: 25px;
    }

    &--offscreen {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      transition: transform ease-in-out 200ms;
      transform: translateX(-@menuWidth);
    }

    &--offscreen&--open {
      transform: translateX(0);
    }

    &--dark {
      color: #fff;
      background-color: #1e1e1e;
      border-right: none;
    }
  }

  &-header:first-child {
    margin-top: 0;
  }

  &-header {
    text-transform: uppercase;
    font-size: 11px;
    color: rgba(23, 24, 28, 0.4); // blends nicer as #abadb3;
    font-weight: bold;
    margin: 15px 0 5px 25px;
    padding-right: 15px;

    // // clip text
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;

    .nocturnal-theme & {
      color: @nocturnalUnselectedTextColor;
    }
  }

  &-link {
    display: block;
    padding: 0.7em 22px;
    color: @textColor;
    border-left: 3px solid transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: inherit;
      // background-color: rgba(@textColor, 0.025);
      background: linear-gradient(to bottom, transparent, var(--primary-color));
      background-size: 100% 100000%;
      background-position: 0 5%;
    }

    &.active {
      border-left: 3px solid var(--primary-color);
      background: linear-gradient(to bottom, transparent, var(--primary-color));
      background-size: 100% 100000%;
      background-position: 0 10%;
      background-image: none;
    }

    .icon {
      margin-right: 10px;
      vertical-align: -2px;
    }

    .sidebar-layout-accordion & {
      padding-left: 40px;
    }

    .nocturnal-theme & {
      color: @nocturnalMutedTextColor;

      &:hover {
        color: @nocturnalMutedTextColor;
        background: rgba(255, 255, 255, 0.05);
      }

      &.active {
        background: rgba(@primaryColor, 0.2);
      }
    }
  }

  &-item {
    display: block;
    margin: 0 23px 15px 23px;
    padding: 0 2px 0.6em 2px;
    color: @textColor;
    border-bottom: 1px solid @borderColor;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: inherit;
      border-color: rgba(34, 36, 38, 0.35);
    }

    .icon {
      margin-right: 10px;
    }

    .right {
      float: right;
      top: 3px;
      margin-right: 5px;
    }

    .nocturnal-theme & {
      color: @nocturnalMutedTextColor;
      border-bottom-color: @nocturnalBorderColor;

      &:hover {
        border-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &-accordion {
    display: none;

    &--active {
      display: block;
    }
  }

  &-divider {
    // height: 1px;
    // margin: 0 0 15px -@menuPadding;
    // background-color: #ccc;
    height: 0;
    margin: 15px 0 15px 0;
    border-top: 1px solid #ccc;

    .nocturnal-theme & {
      background-color: @nocturnalBorderColor;
    }
  }

  &-content {
    padding: 15px 0;

    &--offset {
      margin-left: @menuWidth;
      padding: 20px 25px 10px 25px;
    }

    .app-wrapper {
      min-height: calc(100vh - 30px);
    }
  }

  &-mobile {
    background: @mainMenuBackground;
    padding: 7px 15px 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: -15px 0 10px 0;

    @media @desktop {
      display: none;
    }

    .nocturnal-theme & {
      background: @nocturnalMainMenuBackground;
      border-bottom-color: rgba(255, 255, 255, 0.1);
    }
  }

  &-trigger {
    cursor: pointer;

    &--hidden {
      display: none;
    }
  }

  &__dimmer {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(193, 201, 210, 0.7);
    transition: linear 200ms opacity;
    opacity: 0;
    pointer-events: none;

    &--open {
      opacity: 1;
      pointer-events: auto;
    }

    .nocturnal-theme & {
      background-color: rgba(mix(@nocturnalPageBackground, #000, 50), 0.7);
    }
  }
}
