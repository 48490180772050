@cardRatio: (54/85) * 100%; // in percentages

.groupcontainer {
  overflow-x: auto;
  margin: -1rem;
  padding: 1.5rem 1rem;

  flex: 1 1 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  -webkit-overflow-scrolling: touch;

  .card {
    margin: 0 10%;
    flex: 1 0 auto;

    &:only-child {
      flex: none;
      width: 80%;
      margin: 0 auto;
    }
  }

  .card + .card {
    margin-left: -1rem;
  }
}

.card {
  width: 80%;
  min-width: 220px;
  max-width: 300px;
  display: block;
  position: relative;
  margin: 0 auto;

  &:before {
    content: '';
    display: block;
    height: 0;
    padding-top: @cardRatio;
  }
}

.e-flux {
  .innerCard {
    background: var(--primary-color)
      linear-gradient(to right, var(--primary-color), #ff8500);
  }
}

.innerCard {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 10px;
  padding: 1rem;
  color: rgba(255, 255, 255, 0.9);
  background: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  flex: 1 0 auto;
  width: 140px;
}

.labels {
  flex: 0 0 auto;
  height: 4.5rem;
}

.title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.subtitle,
.number {
  font-size: 12px;
}

.card {
  &:global(.placeholder) {
    .labels {
      margin: 0 2rem 1.5rem;
      text-align: center;
    }

    .title {
      font-size: (16/14) * 1rem;
      font-weight: 700;
    }
  }
}
