.companyInfo,
.contactInfo {
  margin: 0;
  padding: 0 0 0.5rem 0;
}

.companyInfo {
  > * {
    margin-right: 1em;

    :last-child {
      margin-right: 0;
    }
  }
}

.legalLinks {
  @media only screen and (max-width: 767px) {
    display: block;
    padding-top: 0.5rem;
  }

  a {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.contactInfo {
  color: #666;

  a,
  span {
    color: #999;

    :global(.nocturnal-theme) & {
      color: rgba(255, 255, 255, 0.5);
    }

    @media only screen and (max-width: 767px) {
      display: block;
    }

    @media (min-width: 768px) {
      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 1em;
        vertical-align: -2px;
        border-right: 1px solid rgba(34, 36, 38, 0.15);
        margin: 0 0.5rem;

        :global(.nocturnal-theme) & {
          border-right-color: rgba(255, 255, 255, 0.1);
        }
      }
      &:last-child:after {
        display: none;
      }
    }
  }

  a {
    &:hover,
    &:focus,
    &:active {
      color: lighten(#999, 10);
    }

    :global(.nocturnal-theme) & {
      &:hover,
      &:focus,
      &:active {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
