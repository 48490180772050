.toastsWrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.toast {
  background-color: #fafafa;
  border-radius: 4px;
  max-width: 480px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 16px;
  display: flex;
  position: relative;
  cursor: pointer;
}
