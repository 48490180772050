@import (multiple) '../../../semantic/globals/site.variables';
@import '../../../semantic/collections/form.variables';

.evse-controllers-settings {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.evse-controllers-settings label {
  display: block;
  margin: @labelMargin;
  color: @labelColor;
  font-size: @labelFontSize;
  font-weight: @labelFontWeight;
  text-transform: @labelTextTransform;

  .nocturnal-theme & {
    color: @nocturnalLabelColor;
  }
}

.evse-controllers-settings .checkbox label {
  font-weight: @normal;
}

.setting-container {
  width: 100%;
}

.container-dropdown {
  width: 60% !important;
  margin-top: 10px;
  margin-bottom: 15px;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
}
