.containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.playPauseLabel > * {
  margin: 0 !important;
}

.dropdown {
  font-size: 0.8rem;
  padding: 0.55rem;
}

.dropdown .item {
  font-size: 0.8rem !important;
}

.actionsBar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  margin-right: 0.5rem !important;
}

.activityItem {
  background-color: var(--tnic-message-background);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.activityItemHeader {
  display: flex;
  align-items: center;
}

.activityRaw {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 3px;
}

.activityTime {
  margin-right: 0.5rem;
  font-size: 0.8rem;
}

.button {
  font-weight: bold !important;
  box-shadow: none !important;
  border: none !important;
}

.childActivityList {
  margin-top: 0.5rem;
}

.activityRawName,
.activityHeaderName {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.activityHeaderName > p {
  margin-left: 1rem;
}

.activityRaw:nth-child(odd) {
  background-color: var(--tnic-message-background);
}

.activityRaw:nth-child(even) {
  background-color: var(--tnic-message-background);
  filter: brightness(90%);
}

.entityTypeLabel {
  margin-right: 1rem !important;
}

.liveNote {
  font-size: 0.8rem;
  font-weight: 100;
  color: var(--tnic-text-color);
  margin-left: 1rem;
}

.requestResponse {
  display: flex;
  margin-top: 0.5rem;
}

.requestResponse > * {
  flex: 1;
  padding: 0 0.5rem 0 0;
}
