.syncError {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.syncError p {
  margin: 0 0.5rem;
  padding: 0;
}

.syncError p:first-child {
  margin-left: 0;
}

.syncError p:last-child {
  margin-right: 0;
}

.errorMessage {
  margin: 0 0.5rem;
  flex: 1;
}

.entityName {
  font-weight: bold;
  text-transform: uppercase;
}
