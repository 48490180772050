@import '../../styles/responsive';
@import './sizing';

.layout-group {
  // Fixes flexbox quirk:
  // https://stackoverflow.com/q/36247140/907125

  flex: 0 0 auto;
  min-width: 0;
  min-height: 0;

  &.fixed {
    flex: none;
  }

  &.grow {
    // flex: 1 1 1px;
    flex: 1 1 100%;

    &.overflow {
      flex: 1 1 0px;
    }

    .layout.vertical > & {
      height: auto;
    }

    .layout.horizontal > & {
      width: auto;
    }
  }

  &.flex {
    display: flex;
  }

  &.center {
    display: flex;
    flex-flow: inherit;
    align-items: center;
    justify-content: center;
  }

  &.overflow {
    .layout.vertical > & {
      overflow-x: hidden;
      overflow-y: auto;
    }

    .layout.horizontal > & {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .sizingWorkaround();
}
