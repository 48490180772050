@import (multiple) '../../semantic/globals/site.variables';

.nocturnal-theme {
  .ui.segment.segment-off-background {
    background-color: @offDark;
  }
}

.ui.segment.segment-off-background {
  background-color: @offWhite;
}
