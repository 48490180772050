.markdown {
  p,
  ul,
  ol,
  img {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }

  hr {
    height: 1px;
    margin: 0.8em 0;
  }

  h1,
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  li {
    margin-bottom: 0.3em;
    margin-left: 20px;
  }

  p {
    &.text > a > .icon {
      display: none;
    }
  }

  code {
    display: inline-block;
    padding: 0.3rem 0.5rem;
  }

  iframe {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .param {
    padding: 0.4rem 0;
    p.text {
      margin-bottom: 0.5rem;
    }
  }

  .request-payload {
    border: 5px solid rgba(0, 0, 0, 0.2);
    padding: 1rem;
  }

  .request-payload:nth-of-type(n + 2) {
    margin-top: 1rem;
  }

  .required {
    font-size: 0.7em;
    text-transform: uppercase;
    position: relative;
    font-weight: bold;
    //top: -1em;
  }

  .object-title a,
  h2 a,
  td a {
    color: #45474d;
    font-weight: bold;
    text-decoration: none;
  }

  .enum-options {
    font-size: 0.8em;
    margin: 1rem;
    table {
      th {
        padding: 0.8em;
      }
      td {
        padding: 0.5em;
      }
    }
  }

  table {
    border-collapse: collapse;
    margin-bottom: 1.2em;

    th,
    td {
      text-align: left;
      padding: 0.2em 0.8em;
      border: 1px solid rgba(0, 0, 0, 0.1);

      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
    thead tr:first-child th {
      border-top: 0;
    }
    tbody tr:last-child td {
      border-bottom: 0;
    }
  }

  :not(img) {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .atomic a {
    display: block;
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: underline;
    color: #4d659e;
  }

  .math-inline {
    display: inline-block;
    max-width: 100%;
    overflow: auto;
  }
}

// Nocturnal styles

.nocturnal-theme {
  .markdown-body {
    color: rgba(255, 255, 255, 0.9);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      .octicon-link {
        color: rgba(255, 255, 255, 0.8);
      }

      &:hover .anchor .octicon-link:before {
        color: rgba(255, 255, 255, 0.9);
      }
    }

    a {
      color: saturate(lighten(#4183c4, 15), 10);
    }

    .object-title a,
    h2 a,
    td a {
      color: saturate(lighten(#45474d, 50), 10);
      font-weight: bold;
      text-decoration: none;
    }

    hr {
      border-bottom-color: rgba(255, 255, 255, 0.2); //#dfe2e5
    }

    .border {
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
    }

    .border-bottom {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    }

    hr {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }

    .blockquote {
      border-left-color: rgba(255, 255, 255, 0.2);
    }

    h1,
    h2 {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }

    h6 {
      color: rgba(255, 255, 255, 0.6);
    }

    table {
      td,
      th {
        border: 1px solid rgba(255, 255, 255, 0.2);
      }

      th {
        background: #313131;
      }

      tr {
        background: #2a2a2a;
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
      tr:nth-child(2n) {
        background: mix(#313131, #2a2a2a, 50);
      }
    }
  }
}
