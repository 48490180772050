@import '../../../../semantic/globals/site.variables';
@import 'styles/responsive';

.selection-icon {
  float: right;
  margin-right: 0 !important;
  &.disabled {
    color: @lightGrey !important;
  }
  &.clickable {
    cursor: pointer;
  }
}

.billing-plan-segment {
  flex: 1;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  cursor: pointer;
  .select-plan-button {
    width: 100%;
    margin-left: 0;
  }

  @media @mobile {
    width: 100% !important;
  }
}

.billing-plan-segment {
  border-top: none !important;
  border-right: 1px solid @lightGrey !important;

  @media @mobile {
    border-right: none !important;
    border-top: 1px solid @lightGrey !important;
  }
}

.billing-plan-segment-selected {
  border: 1px solid blue !important;
}

.segmentGroup {
  display: flex;
  flex-direction: row !important;

  @media @mobile {
    flex-direction: column !important;
  }
}

.column {
  width: 100% !important;
}

.row {
  display: flex;
  flex-direction: row !important;
  flex-wrap: unset !important;

  @media @mobile {
    flex-direction: column !important;
  }
}
