.container {
  margin-bottom: 0.5rem;
  border-radius: 2px;
  padding: 1rem;
  display: flex;
}

.cardNumber {
  font-family: monospace;
  margin-bottom: 0;
}

.icon {
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.type {
  font-size: 0.8rem;
}

.details {
  flex: 1;
  padding: 0 0.5rem;
}
