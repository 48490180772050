:global {
  #edit-provider-form > div {
    margin-bottom: 1rem;
  }
  #edit-provider-form > .segment > div {
    margin-bottom: 1rem;
    padding: 0;
  }
  #edit-provider-form > .segment > .ui.info.message {
    padding: 1rem;
  }
}
