.activeMenuItem {
  border-left: 2px solid var(--primary-color);
  background-color: var(--primary-color-hover) !important;
}

.menuItem {
  text-decoration: none;
  color: black;
}

.menuItem:hover {
  background-color: var(--primary-color-hover) !important;
}
