@import 'styles/responsive';

.formField {
  padding-top: 0;
  @media @mobile {
    padding-top: 1em;
  }
}

.column {
  width: 50% !important;

  @media @mobile {
    width: 100% !important;
    margin-bottom: 1rem; /* Adjust as needed for spacing */
  }
}

.billingDetails {
  display: flex;
  flex-direction: row !important;
  width: 10%;

  @media @mobile {
    flex-direction: column !important;
  }
}
