.markdown-body .linked-heading {
  // Needed while using a fixed heading.
  padding-top: 50px + 24px;
  margin-top: -50px;

  a {
    color: inherit;
    font-size: 15px;
    margin-left: -1.4em;
    vertical-align: middle;
    opacity: 0;
  }

  &:hover a {
    opacity: 1;
    text-decoration: none;
  }
}
