.description {
  padding: 0;
  font-size: 0.9em;
}

.roamingModeFieldContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.roamingModeFieldContainer > * {
  margin-right: 1rem;
}
