.productCard {
  padding: 15px 0;
}

.productDescription {
  padding-top: 0.5rem;
}

.productHeader {
  margin-top: 0;
}

.textAlignCenter {
  text-align: center;
}

.actionButton {
  padding: 0;
  margin: 0;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .productDescription {
    padding-top: 0.5rem;
    padding-left: 2em !important;
  }
}
