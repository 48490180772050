@import '../../../../semantic/globals/site.variables';
@import 'styles/responsive';

.search-filters-modal,
.nocturnal-theme .search-filters-modal {
  @media @mobile {
    margin-bottom: 10px;
    > .ui.label {
      cursor: text;
    }
  }

  > .ui.label {
    cursor: pointer;
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: #fff;

    &:hover {
      background: var(--primary-color);
    }
  }
}
