.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 240px; // 270px;

  :global(.ui.grid .row .column:not(:last-child)) & {
    @media only screen and (max-width: 767px) {
      padding-bottom: 2rem;
    }
  }

  &.condensed {
    min-height: 180px;
  }
}

.header {
  display: flex;
  flex: 0 1 auto;

  :global(.ui.header) {
    flex: 1 1 auto;
    margin-bottom: 1rem;
  }

  .toggle {
    flex: 0 1 auto;
    align-self: center;
  }
}

.container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  border: 1px solid rgba(34, 36, 38, 0.15);
  background: #fff;
  border-radius: 4px;

  :global(.nocturnal-theme) & {
    border-color: rgba(255, 255, 255, 0.2);
    background-color: #2a2a2a;
  }
}

.contentContainer {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;

  > :global(.ui.grid) {
    flex: 1 1 auto;
  }
}

.actionContainer {
  flex: 0 1 auto;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  display: flex;
  flex-direction: row;

  :global(.nocturnal-theme) & {
    border-top-color: rgba(255, 255, 255, 0.2);
  }
}

.actionLink {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid rgba(34, 36, 38, 0.15);
  font-weight: bold;
  padding: 11px 0.5rem;
  cursor: pointer;

  && {
    color: var(--primary-color);

    &:hover {
      color: var(--primary-color);

      :global(.nocturnal-theme) & {
        // Nocturnal hover is too dark
        color: var(--primary-color);
      }
    }
  }

  :global(.nocturnal-theme) & {
    border-right-color: rgba(255, 255, 255, 0.2);
  }

  &:last-child {
    border-right: none;
  }
}
