.borderless-blue-button {
  background: transparent;
  border: none;
  box-shadow: transparent;
  color: #008cd9;
  margin: 0 1rem;
  cursor: pointer;
  font-family:
    Inter,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Helvetica,
    'Segoe UI',
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji';
}
