@import '../../../styles/responsive';

.date-range {
  .fields {
    display: flex;
    align-items: center;
  }
  .fields > .field {
    @media @mobile {
      padding: 5px 0 !important;
    }
  }

  > .fields {
    @media @mobile {
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }

  .layout-group {
    position: relative;
  }

  .divider {
    opacity: 0.2;
    margin: 0 0.5em;

    @media @mobile {
      display: none;
    }
  }
}
