@import 'styles/responsive';

.formContainer {
  display: grid;
  grid-template-columns: 1fr;

  @media @mobile {
    display: flex;
    flex-direction: column;
  }
}

.responsiveWidth {
  width: 50%;

  @media @mobile {
    width: 100%;
  }
}

.fieldTopPaddingForMobile {
  padding-top: 0;

  @media @mobile {
    padding-top: 1em;
  }
}

.newUserFieldLastName {
  padding-right: 1.5em !important;

  @media @mobile {
    padding-right: 0.5em !important;
  }
}

.newUserFieldEmail {
  padding-right: 1em !important;

  @media @mobile {
    padding-right: 0 !important;
  }
}

.deliveryAddressColumnPaddingRight {
  padding-right: 1rem !important ;
}

.deliveryAddressColumnMobilePaddingRight {
  @media @mobile {
    padding-right: 0.5rem !important;
  }
}

.deliveryAddressLastName {
  padding-left: 1rem !important ;

  @media @mobile {
    padding-left: 0.5rem !important;
  }
}

@media @mobile {
  .rowResetPaddingTop {
    padding-top: 0 !important;
  }

  .rowResetPaddingBottom {
    padding-bottom: 0 !important;
  }
}
