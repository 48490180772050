/* HEADER */

/* ITEM STACKS */

.itemGrid {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.itemGridColumn {
  margin-left: 1rem;
  margin-right: 1rem;
  flex: 0 1 auto;
}

@media only screen and (min-width: 992px) {
  .itemGrid {
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1 0;
  }

  .itemGridColumn {
    flex: 1 1 0;
    width: auto;
    display: flex;
    justify-content: center;
  }

  .itemGridColumn:only-child {
    flex: 0 1 auto;
  }
}

.itemGroup {
  > :global(.item) {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;

    :global(.ui.header) {
      font-size: 1.71428571em; // 24px

      @media only screen and (max-width: 767px) {
        font-size: 1.28571429em; // 18px
      }
    }
  }

  &:global(.ui.unstackable.items) > :global(.item) > :global(.image),
  &:global(.ui.unstackable.items) > :global(.item) > :global(.image) > img {
    width: auto !important;
  }

  > :global(.ui.placeholder) {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    min-width: 200px;
  }
}

.itemSubheader {
  margin: 0;
  font-weight: 500;
}

svg.itemIcon:global(.ui.icon) {
  height: 32px;
  width: 32px;

  // Make icons smaller on mobile
  // @media only screen and (max-width: 767px) {
  //   width: 28px;
  //   height: 28px;
  // }
}

.mapLink {
  display: block;
  min-height: 250px;
  margin: -1rem;
  padding: 3rem 0;
  flex: 1 0 auto;
  border-radius: 2px;

  background-size: cover;
  background-position: 50% 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  :global(.ui.header) {
    color: var(--tnic-primary-color);
  }

  &:hover :global(.ui.header) {
    color: var(--tnic-primary-color-hover);
  }
}

.widgetTable {
  // don’t shrinkwrap content
  width: 100%;

  :global(.ui.table) {
    // negate widget box padding
    margin: -1rem;
    width: calc(100% + 2rem);

    border: none;
  }
}
