@import '../../semantic/globals/site.variables';
@import '../../styles/responsive.less';

.progress-bar-wrapper {
  padding: 1em;
}

.progress-bar-wrapper,
.progress-bar-wrapper * {
  box-sizing: border-box;
}

.progress-steps-bar {
  margin: 0 0 1rem 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.progress-step {
  list-style: none;
  position: relative;
  text-align: center;
  flex: 1 1 auto;
}

.progress-step::before {
  content: '';
  position: absolute;
  top: 50%;
  height: 3px;
  width: 100%;
  right: 50%;
  transform: translateY(-50%);
  z-index: -1;
  background: linear-gradient(to left, @lightGrey 50%, @blue 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 200ms ease-in-out;
}

.progress-step:first-child::before {
  content: none;
}

.progress-step .step-circle {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  line-height: 32px;
  display: inline-flex;
  transform-origin: center;
  align-items: center;
  justify-content: center;
}

.step-circle {
  transition: background-color 250ms ease;
}

.progress-step .step-label {
  font-size: 12px;
  position: absolute;
  white-space: nowrap;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
}

@media @mobile {
  .progress-step .step-label {
    white-space: normal;
    width: 90%;
    text-align: center;
  }

  .progress-step .step-label-subtitle {
    display: block;
    width: 100%;
    text-align: center;
    white-space: normal;
  }
}

.progress-step.current .step-label {
  font-weight: 600;
}

.progress-step .step-label-subtitle {
  display: block;
}

.progress-step.clickable {
  cursor: pointer;
  &:hover {
    .step-circle {
      background-color: darken(@blue, 10%);
    }
  }
}

.progress-step.completed .step-circle {
  background-color: @blue;
  color: white;
  animation: de-emphasize 400ms ease-in-out forwards;
}

.progress-step.current .step-circle {
  border: 2px solid @blue;
  background-color: white;
  color: @blue;
  animation: emphasize 400ms ease forwards;
}

.progress-step.upcoming .step-circle {
  border: 2px solid @lightGrey;
  background-color: white;
  animation: de-emphasize 400ms ease-in-out forwards;
}

.progress-step.completed::before,
.progress-step.current::before {
  background-position: left bottom;
}

@keyframes emphasize {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes de-emphasize {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
