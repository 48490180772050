@import '../../semantic/globals/site.variables';

/* Responsive.less */

@tinyBreakpoint: 380px;
@mobileBreakpoint: 768px;
@tabletBreakpoint: 1128px;

@tinyMax: (@tinyBreakpoint - 1);
@mobileMax: (@mobileBreakpoint - 1);
@tabletMax: (@tabletBreakpoint - 1);

@tiny: ~'only screen and (max-width: @{tinyMax})';
@mobile: ~'only screen and (max-width: @{mobileMax})';
@desktop: ~'only screen and (min-width: @{mobileBreakpoint})';
@tablet: ~'only screen and (min-width: @{mobileBreakpoint}) and (max-width: @{tabletMax})';

/* end Responsive.less */

@menuWidth: 250px;
@menuPadding: 25px;

.portal-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &.with-env-banner {
    max-height: calc(100vh - @environmentBannerHeight);
  }

  &-page-head {
    flex: 0 1 auto;
    background: @mainMenuBackground;

    .nocturnal-theme & {
      background: @nocturnalMainMenuBackground;
    }
  }

  &__head-brand {
    padding: 10px 14px 5px 14px;

    @media @desktop {
      padding: 20px 25px 5px 25px;
    }
  }

  &__head-menu .ui.menu {
    display: none;

    @media @desktop {
      display: block;
    }

    > .layout {
      padding: 0 25px;
    }

    .layout-group {
      display: flex;
      align-items: inherit;
      flex-direction: inherit;
      position: relative;
      bottom: -2px;

      &:last-child {
        .item:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-mobile-head {
    background: @mainMenuBackground;
    padding: 5px 0 10px 14px;
    border-bottom: 2px solid rgba(34, 36, 38, 0.15);

    > .layout {
      overflow: visible;
    }

    .ui.menu {
      margin-bottom: 0;

      .item > svg.dropdown.icon {
        margin-left: auto;
      }
    }

    @media @desktop {
      display: none;
    }

    .nocturnal-theme & {
      background: @nocturnalMainMenuBackground;
      border-bottom-color: rgba(255, 255, 255, 0.1);
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    height: 100%;

    @media @desktop {
      overflow: hidden;
    }
  }

  &-sidebar,
  &-mobile-sidebar {
    display: none;
    width: @menuWidth;
    background: @mainMenuBackground;
    padding: 25px 0 25px 0;
    background-color: @mainMenuBackground;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    z-index: 10;

    .nocturnal-theme & {
      background: @nocturnalMainMenuBackground;
      border-right-color: rgba(255, 255, 255, 0.1);
    }
  }

  &-sidebar {
    flex: 0 1 auto;

    @media @desktop {
      display: block;
    }
  }

  @media @mobile {
    &-mobile-sidebar {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      transform: translateX(-@menuWidth);
      transition: ease-in-out 200ms transform;
    }

    &-mobile-sidebar&-mobile-sidebar--open {
      transform: translateX(0);
    }

    &__dimmer {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(193, 201, 210, 0.7);
      transition: linear 200ms opacity;
      opacity: 0;
      pointer-events: none;

      &--open {
        opacity: 1;
        pointer-events: auto;
      }

      .nocturnal-theme & {
        background-color: rgba(mix(@nocturnalPageBackground, #000, 50), 0.7);
      }
    }
  }

  &-content {
    flex: 1 1 auto;

    @media @desktop {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    > .layout {
      padding-top: 25px;
      padding-bottom: 35px;

      @media @desktop {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }

  &-trigger {
    cursor: pointer;
    padding: 10px 14px 10px 25px;
  }

  &-link {
    display: block;
    padding: 0.7em 22px;
    color: @textColor;
    border-left: 3px solid transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      color: inherit;
      // background-color: rgba(@primaryColor, 0.06);
      background: linear-gradient(to bottom, transparent, var(--primary-color));
      background-size: 100% 100000%;
      background-position: 0 5%;
    }

    &.active {
      border-left: 3px solid var(--primary-color) !important;
      // background-color: rgba(255, 255, 255, 0.05) !important;
      background: linear-gradient(to bottom, transparent, var(--primary-color));
      background-size: 100% 100000%;
      background-position: 0 10%;
    }

    .icon {
      margin-right: 10px;
      vertical-align: -2px;
    }

    .sidebar-layout-accordion & {
      padding-left: 40px;
    }

    .nocturnal-theme & {
      color: @nocturnalMutedTextColor;

      &:hover {
        color: @nocturnalMutedTextColor;
        background: rgba(255, 255, 255, 0.05);
      }

      &.active {
        border-left-color: @nocturnalPrimaryColor;
        background: rgba(@nocturnalPrimaryColor, 0.2);
      }
    }
  }
}
