@import 'styles/responsive';

.column {
  width: 100% !important;
}

.row {
  display: flex;
  flex-direction: row !important;
  flex-wrap: unset !important;

  @media @mobile {
    flex-direction: column !important;
  }
}
