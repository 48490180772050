.socialLoginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  margin-bottom: 0.5rem;
}
