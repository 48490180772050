.addPaymentMethod {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem;
}

.cardFrame > * {
  margin-bottom: 0.8rem;
  margin-left: 0 !important;
}

:global {
  .card-frame iframe {
    height: 40px !important;
  }
}

.paymentConfirmationModal {
  text-align: center;
  padding: 2rem;
}

.hint {
  margin-top: 1rem;
  color: #aaaaaa;
  font-size: 0.8rem;
}

.confirmationContent {
  display: flex;
  align-items: center;
}

.confirmationContent div {
  margin-left: 1rem;
}
