.container {
  border: 1px solid #e2e2e2;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  & > div {
    margin-bottom: 0.5rem;
  }
}

.field {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  & > div {
    margin-right: 1rem;
    flex: 1;
  }
}

.code {
  text-decoration: underline;
  border-radius: 0.5rem;
  background: #212121;
  color: white;
  padding: 0.5rem 1rem;
}
