@import '../../styles/responsive';

.container {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;

  @media @tablet {
    flex-direction: column;
    align-items: flex-start;
  }
  @media @mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.buttons {
  display: flex;
  align-items: center;
}
